@import '../variables.scss';

.aumo_foreground {
  background-color: $white;
  border-radius: 12px;
  padding: 20px 24px 12px 24px;
  position: relative;
  color: $black;
  margin-top: 30px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: $tablet) {
    padding: 28px;
  }
  &.aumo_foreground--wide {
    max-width: 622px;
  }
}
.aumo_foreground--blip {
  position: absolute;
  bottom: -12px;
  fill: $white;
  transform: rotate(270deg);
  height: 24px;
  width: 48px;
}
