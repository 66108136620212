@import '../variables.scss';

.aumo_not-found-page,
.aumo_try-later-page,
.aumo_invitation-withdrawn-page,
.aumo_error-boundary-page,
.aumo_invitation-expired-page {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // overflow: hidden;
  .aumo_content {
    padding-bottom: 0;
  }
  .aumo_body-text {
    font-size: 14px;
  }
  .aumo_speech-bubble {
    background: white;
    color: $black;
    padding: 35px 15px 50px 15px;
    text-align: center;
    box-shadow: 2px 8px 16px 0 rgba(0, 0, 0, 0.1);
    max-width: 500px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;

    .aumo_speech-bubble-arrow {
      border-bottom-color: white;
      bottom: -23px;
      transform: rotate(90deg);
      left: 50%;
    }
    @media screen and (min-width: $tablet) {
      margin: auto;
    }
  }
  .aumo_person {
    flex-direction: column;
    margin-top: 45px;
  }
  .aumo_person--text {
    text-align: center;
  }
  .aumo_person--name {
    font-weight: 400;
  }
  .aumo_person--company {
    font-weight: 200;
  }
  .aumo_heading-text {
    text-align: center;
    color: white;
    margin: 16px auto 40px;
  }
}
.aumo_not-found-page--links,
.aumo_invitation-expired-page--links {
  text-align: center;
}
.aumo_not-found-page--link,
.aumo_invitation-expired-page--link {
  color: rgba(238,44,124,1);
  width: 77px;
  margin-top: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  text-decoration: none;
  border: none;
  >img {
    width: 44px;
    height: 44px;
  }
  >span {
    display: inline-block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 17px;
    font-size: 12px;
  }

  &:hover {
    border: none;
  }
}

.aumo_invitation-withdrawn-page {
  .aumo_body-text a {
    color: rgb(47, 164, 218);
    text-decoration: none;
    border: none;
    &:hover {
      color: darken($color: rgb(47, 164, 218), $amount: 20%)
    }
  }
  .aumo_speech-bubble {
    padding-bottom: 30px;
  }
}
