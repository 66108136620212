$heading-font: 'Montserrat', sans-serif;
$body-font: 'Nunito', sans-serif;

$white: white;
$off-white: #f3f5f6;

$blue: #1da5de;
$blue--bright: #21BCFF;
$blue--dark: #0089C4;

@function blue-opacity($opacity) {
  @return rgba(29, 165, 222, $opacity);
}

$darker-blue: #188cbc;
$purple: #8951a0;
$dark-grey: #4a4a4a;
$mid-grey: #899fad;
$light-grey: #dae1e5;
$red: #e83034;
$black: #0b040e;

$pink: #ee2c7c;
$pink--bright: #F04B8F;
$pink--dark: #CA2569;

$navy: #476070;
$aqua-green: #18bbb6;
$aqua-green--light: #5dcfcc;

$loader-color: rgb(178, 178, 178);

$font-size--default: 14px;
$font-size--tablet: 18px;

$ease: cubic-bezier(0.32, 0, 0.37, 1) !default;
$duration--short: 0.0875s !default;
$duration: 0.175s !default;
$duration--long: 0.350s !default;

$content-padding: 16px;
$page-padding: 16px;

$input-border-color: $off-white;
$input-background-color: $off-white;
$input-placeholder-color: lighten($mid-grey, 10%);
$input-focus-outline-color: rgba(151, 151, 151, 1);
$input-error-message-color: $white;
$input-error-border-color: $red;
$input-error-label-color: $red;

// React-Select
$select-input-border-color: $input-border-color;
$select-input-border-focus: $input-focus-outline-color;
$select-input-box-shadow-focus: none;
$select-input-border-radius: 3px;
$select-input-bg: $input-background-color;
$select-text-color: $black;
$select-input-placeholder: $input-placeholder-color;

$tablet: 600px;
$desktop: 900px;
