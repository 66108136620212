@import './variables.scss';
@import './components/util/FormComponent.scss';

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
  height: 100%
}
*, *:before, *:after {
  box-sizing: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  &::placeholder {
    font-family: inherit;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $body-font;
  background: $white;
  background-attachment: fixed;
  font-size: $font-size--default;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  color: $white;
  min-height: 100%;
}

#root, .aumo_app, .aumo_body {
  min-height: 100vh;
}

svg {
  opacity: 1;
}

a {
  color: $black;
}

button {
  cursor: pointer;
}

.aumo_app {
  text-align: center;
}

.aumo_body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.aumo_splash-screen {
  opacity: 0;
  transition: $duration opacity $ease;
}

.aumo_route-wrapper {
  width: 100%;
}

.aumo_route-navigation--fade-enter {
  opacity: 0.01;
}

.aumo_route-navigation--fade-enter.aumo_route-navigation--fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.aumo_route-navigation--fade-exit {
  opacity: 1;
}

.aumo_route-navigation--fade-exit.aumo_route-navigation--fade-exit-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}
