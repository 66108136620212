@import '../../variables.scss';
.aumo_form-component.has-label .aumo_form-component--icon {
  top: 24px;
}

.aumo_form-component + .aumo_form-component {
  margin-top: 20px;
}

.aumo_form-component--icon {
  position: absolute;
  top: 13.5px;
  left: 12px;
  width: 16px;
  text-align: center;
  >svg {
    height: 20px;
    width: 20px;
    fill: $mid-grey;
  }
}
