@import '../../variables.scss';

.aumo_next-and-back-button-group {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  max-width: 420px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;

  >div:first-child {
    flex-basis: 110px;
  }

  >div:last-child {
    flex-grow: 1;
  }

  .cui_primary-button {
    width: 100%;
    margin-bottom: 10px;
  }

  @media screen and (min-width: $tablet) {
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    padding: 0 64px;
    flex-direction: column;

    .cui_secondary-button {
      position: absolute;
      left: 4px;
    }

    .cui_primary-button {
      width: auto;
      margin-bottom: 0;
    }
  }

  .aumo_icon #Combined-Shape {
    fill: $white;
  }
}

.aumo_next-and-back-button-group--padding {
  padding: 10px;
}
