@import '../variables.scss';

.aumo_body-text {
  margin-bottom: 0.43em;
  margin-right: 0.3em;
  opacity: 0.75;
  @media screen and (min-width: $tablet) {
    font-size: $font-size--tablet;
  }
}

.aumo_body-text--bold {
  font-weight: bold;
}
