@import '../variables.scss';

.aumo_heading-text {
	font-size: 20px;
  line-height: 22px;
	font-weight: bold;
  margin: 0 auto 24px;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-line;
  @media screen and (min-width: $tablet) {
    font-size: 28px;
    line-height: 30px;
  }
}
