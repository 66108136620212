@import '../../variables.scss';

.aumo_next-button {
  width: 100%;
  position: relative;

  @media screen and (min-width: $tablet) {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .aumo_icon {
    stroke: $white;
    fill: $white;
  }

  .aumo_icon-email {
    height: 18px;
    width: 22px;
    position: relative;
    top: 1px;
    left: 8px;
  }

  .aumo_icon-chevron-right {
    height: 11px;
    width: 11px;
    position: relative;
    left: -2px;
  }
}
