@import '../variables.scss';

$line-thickness: 6px;

.aumo_progress-indicator {
  text-align: center;
  padding: 32px 0 16px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  line-height: 15px;
}

.aumo_progress-indicator .aumo_dot {
  margin-left: 14px;
  margin-right: 14px;
}

.aumo_progress-indicator--line {
  height: $line-thickness;
  flex-grow: 1;
}

.aumo_progress-indicator--plug--start {
  display: block;
  position: relative;
}

.aumo_progress-indicator--plug--end {
  transform-origin: right;
}

.aumo_progress-indicator--plug--end, .aumo_progress-indicator--line--right {
  opacity: 0.33;
}

.aumo_progress-indicator--line--right {
  transform-origin: left;
}

.aumo_progress-indicator .aumo_dot {
  transition: opacity 0.2s;
  margin-left: 14px;
  margin-right: 14px;
}
