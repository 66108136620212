@import '../variables.scss';

.aumo_speech-bubble {
  padding: 20px;
  border-radius: 5px;
  background-color: rgba(238, 238, 238, 1);
  position: relative;
  .aumo_speech-bubble-arrow {
    width: 0;
    height: 0;
    border-width: 21px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: rgba(238, 238, 238, 1);
    border-bottom-width: 25px;
    position: absolute;
    top: -21px;
    transition: left 0.3s ease;
    display: none;
  }
  &.angle-top-1 .aumo_speech-bubble-arrow,
  &.angle-top-2 .aumo_speech-bubble-arrow,
  &.angle-top-3 .aumo_speech-bubble-arrow,
  &.angle-top-4 .aumo_speech-bubble-arrow,
  &.angle-top-5 .aumo_speech-bubble-arrow {
    display: block;
  }
  &.angle-top-1 .aumo_speech-bubble-arrow {
    left: 12%;
    transform: rotate(90deg);
    top: -23px;
  }
  &.angle-top-2 .aumo_speech-bubble-arrow {
    left: 24%;
    transform: none;
    top: -40px;
  }
  &.angle-top-3 .aumo_speech-bubble-arrow {
    left: 42%;
    transform: none;
    top: -40px;
  }
  &.angle-top-4 .aumo_speech-bubble-arrow {
    left: 62%;
    transform: none;
    top: -40px;
  }
  &.angle-top-5 .aumo_speech-bubble-arrow {
    left: 74%;
    transform: rotate(270deg);
    top: -23px;
  }

  &.angle-bottom-1 .aumo_speech-bubble-arrow,
  &.angle-bottom-2 .aumo_speech-bubble-arrow,
  &.angle-bottom-3 .aumo_speech-bubble-arrow,
  &.angle-bottom-4 .aumo_speech-bubble-arrow,
  &.angle-bottom-5 .aumo_speech-bubble-arrow {
    display: block;
    top: auto;
  }
  &.angle-bottom-1 .aumo_speech-bubble-arrow {
    left: 8%;
    transform: rotate(-90deg);
    bottom: -23px;
  }
  &.angle-bottom-2 .aumo_speech-bubble-arrow {
    left: 23%;
    transform: rotate(180deg);
    bottom: -40px;
  }
  &.angle-bottom-3 .aumo_speech-bubble-arrow {
    left: 43%;
    transform: rotate(180deg);
    bottom: -40px;
  }
  &.angle-bottom-4 .aumo_speech-bubble-arrow {
    left: 63%;
    transform: rotate(180deg);
    bottom: -40px;
  }
  &.angle-bottom-5 .aumo_speech-bubble-arrow {
    left: 78%;
    transform: rotate(-270deg);
    bottom: -23px;
  }
  .aumo_body-text p {
    color: $black;
  }
}
