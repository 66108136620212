@import '../variables.scss';

.aumo_page {
  background-attachment: fixed;
  min-height: 100%;
}
.aumo_page--fade-enter {
  opacity: 0.01;
  transform: translateX(50px);
}

.aumo_page--fade-enter.aumo_page--fade-enter-active {
  opacity: 1;
  transition: opacity 200ms, transform 200ms ease-in;
  transform: translateX(0px);
}

.aumo_page--fade-exit {
  opacity: 1;
  transform: translateX(0px);
}

.aumo_page--fade-exit.aumo_page--fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms, transform 200ms ease-in;
  transform: translateX(-50px);
}

.aumo_page--no-background {
  .aumo_foreground  {
    box-shadow: none;
  }
}
