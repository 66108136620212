@import '../variables.scss';

.aumo_content {
  padding: $content-padding;
  width: 100%;
  flex-grow: 1;
  overflow: visible;
  text-align: center;
  @media screen and (min-width: $tablet) {
    text-align: center;
    max-width: none;
  }
  &:focus {
    outline: none;
  }
}
.aumo_content--inner {
  width: 100%;
  max-width: 800px;
  margin: auto;
}
